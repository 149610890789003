import styled from "styled-components";

export const AboutWrapper = styled.div`
    margin-bottom: 180px;
`
export const SectionHeading = styled.h2`
    font-size: 45px;
    font-family: 'Courier Prime', sans-serif;
    text-shadow: 0 0 1px #FFF, 0 0 1px #FFF, 0 0 1px #FFF, 0 0 1px #FFF;
    color: #202633;
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
`

export const AboutMeP = styled.p`
    font-family: 'Montserrat', 'sans-serif';
    color: #FFF;
    width: 65%;
    letter-spacing: 0.1em;
    line-height: 23px;
    font-weight: 300;

    @media screen and (max-width: 768px){
        width: 100%;
        font-size: 13px;
    }
`

export const AboutMeTechnologies = styled.div`
    font-family: 'Montserrat', sans-serif;
    color: #FFF;
    width: 65%;
    letter-spacing: 0.1em;
    line-height: 23px;
    font-weight: 300;

    @media screen and (max-width: 768px){
        width: 100%;
        font-size: 13px;
    }
`

export const UList = styled.ul`
    list-style-type: none;
    font-size: 13px;
`

export const List = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 30px 0;

    p{
        font-family: 'Titillium Web', 'sans-serif';
        margin-bottom: 10px;

        @media screen and (max-width: 768px){
            margin-top: 10px;
        }
    }

    @media screen and (max-width: 768px){
        width: 100%;
        flex-direction: column;
    }
`