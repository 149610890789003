import { BlogPreviewWrapper } from "./blogsPreview.style";
const BlogsPreview = ({ title, description, date, minutesRead, image }) => {
    return(
        <BlogPreviewWrapper>
            {/* <img src={ image } alt='read this amazing article!' /> */}
            <div>
                <h3>{ title }</h3>
                <p className="trimmedArticle"> { description }</p>
                <p className="blog-info"> { date } | { minutesRead } minute read </p>
            </div>
            <hr />
        </BlogPreviewWrapper>
    )
}

export default BlogsPreview;