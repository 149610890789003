import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavbarWrapper, ListOptions, MobileListHamburger, MobileListOptions } from './navbar.styles';

import resume from '../../../assets/GodwinOlleteyResume.pdf'

import { IoCloseSharp } from 'react-icons/io5'; //importing closing icon for mobile nav
import { FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa' //importing icons for users to contact me 
import { AiOutlineMail } from 'react-icons/ai' //importing icon for email

const Navbar = () => {
    const [toggle, setToggle] = useState(null)

    const openMenuBar = () => {
        setToggle(true); //set the toggle to true and open the menu
    }

    const closeMenuBar = () => {
        setToggle(false); //set the toggle to false and close the menu
    }

    return(
        <div>
            <NavbarWrapper>
                <div className="logo">
                    <a href="/"><p>GODWIN<span>RODRICK</span></p></a>
                </div>
                <ListOptions>
                    <ul>
                        <li className="hover-effect"><a href="/#about-godwin">.about()</a></li>
                        <li className="hover-effect"><a href="/#projects">.projects()</a></li>
                        <li className="hover-effect"><Link to="/blog">.blog()</Link></li>
                        <li><span><a href={ resume } target="_blank" rel="noreferrer">.resume()</a></span></li>
                    </ul>
                </ListOptions>
                <MobileListHamburger onClick={ openMenuBar }>
                    <div></div>
                    <div></div>
                    <div></div>
                </MobileListHamburger>
            </NavbarWrapper>
            {
                toggle && (
                <MobileListOptions>
                    <span className="close-menu" onClick={ closeMenuBar }><IoCloseSharp /></span>
                    <ul>
                        <li><a className="mobile-link" href="/#about-godwin" onClick={ closeMenuBar }>.about()</a></li>
                        <li><a className="mobile-link" href="/#projects" onClick={ closeMenuBar }>.projects()</a></li>
                        <li><Link className="mobile-link" to="/blog" onClick={ closeMenuBar }>.blog()</Link></li>
                        <li><span><a className="mobile-link" href={ resume } target="_blank" rel="noreferrer" onClick={ closeMenuBar }>.resume()</a></span></li>
                    </ul>
                    <div className="connect-with-me">
                        <p>Connect With Me</p>
                        <span><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/godwin-olletey-4438a4166/"><FaLinkedin /></a> </span>
                        <span><a target="_blank" rel="noreferrer" href="https://www.instagram.com/godwin.codes/"><FaInstagram /></a> </span>
                        <span><a target="_blank" rel="noreferrer" href="https://github.com/godwinolle"><FaGithub /></a> </span>
                        <span><a href="mailto:godwinolle@gmail.com"><AiOutlineMail /></a> </span>
                    </div>
                </MobileListOptions>
                )
            }
        </div>
    )
}

export default Navbar;