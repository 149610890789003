import styled from "styled-components";

export const Wrapper = styled.div`
    width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`

export const LandingWrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    color: #FFF;
    letter-spacing: 0.01em;
    display: flex;
    justify-content: space-around;
    margin: 140px auto 25% auto;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin: 75px 0 100px 0;
    }
`
export const DescriptionWrapper = styled.div`
    animation: myAnim 1s ease 0s 1 normal forwards;
    @keyframes myAnim {
        0% {
            opacity: 0;
            transform: translateY(-250px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }


`
export const ImageWrapper = styled.div`
    animation: myAnim 1s ease 0s 1 normal forwards;

    @media screen and (max-width: 768px) {
        margin-bottom: 25px;
    }

    @keyframes myAnim {
        0% {
            opacity: 0;
            transform: translateY(-250px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
`

export const Heading = styled.h1`
    font-family: 'Titillium Web', sans-serif;
    text-shadow: 0 0 1px #FFF, 0 0 1px #FFF, 0 0 1px #FFF, 0 0 1px #FFF;
    color: #202633;
    font-size: 85px;
    margin: 10px 0;
    
    @media screen and (max-width: 768px) {
        font-size: 40px;
    }
`

export const Description = styled.h2`
    font-size: 38px;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`

export const HelloWrapper = styled.p`
    font-size: 23px;
    letter-spacing: 0.06em;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`

export const Tagline = styled.p`
    font-family: 'Titillium Web', sans-serif;
    margin: 10px 0;
    font-size: 20px;
    font-weight: 500;
    width: 65%;
    letter-spacing: 0.06em;
    animation: myAnim 1s ease 0s 1 normal forwards;
    @keyframes myAnim {
        0% {
            opacity: 0;
            transform: translateY(250px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        font-size: 16px;
    }
`

export const SideBar = styled.div`
    color: #FFF;
    transform: rotate(90deg);
    position: fixed;
    bottom: 25%;
    left: 90%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-size: 13px;

    hr{
        height: 40px;
        width: 1px;
        margin: 15px auto;
        transform: rotate(-90deg);
    }

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const SideBarLeft = styled.div`
    color: #FFF;
    position: fixed;
    bottom: 15%;
    right: 97%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-size: 20px;
    display: flex;
    flex-direction: column;

    .icons{
        margin-bottom: 20px;
    }

    hr{
        height: 40px;
        width: 1px;
        margin: 15px auto 0 auto;
    }

    hr:first-child{
        margin-bottom: 15px;
    }

    @media screen and (max-width: 768px){
        display: none;
    }
`

export const SideBarLinks = styled.a`
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    text-decoration: none;
    transform: 0.2s ease;

    :hover{
        animation: linkAnim 0.7s ease 0s 1 normal forwards;
    }

    @keyframes linkAnim {
        0% {
          transform: scale(1);
        }
        
        100% {
          transform: scale(1.3);
        }
    }
`