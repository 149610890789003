import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';


//importing sections
import Navbar from './components/necessities/navigation/navbar';
import Footer from './components/necessities/footer/footer';

import MainPage from './Pages/mainPage/MainPage';
import BlogPage from './Pages/TechBlog/blogView/BlogPage';
import PostView from './Pages/TechBlog/blogView/PostView';
import NotFound from './Pages/404Page/NotFound';

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={ <NotFound /> } />
          <Route exact path="/" element={ <> <Navbar /> <MainPage /> <Footer /> </>} />
          <Route exact path="/blog" element={ <> <Navbar /> <BlogPage /> <Footer /> </>} />
          <Route path="/blog/:id" element={ <> <Navbar /> <PostView /> <Footer /> </>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
