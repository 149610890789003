//VIEWING THE SPECIFIC POST YOU CLICKED ON BY USING PARAMETERS TO LOCATE THEIR INFORMATION
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import ReactMarkdown from 'react-markdown';
// import Markdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';

//image that generates everytime a user comments
import CommentsImage from '../../../assets/comments.svg'

import { PostViewWrapper, CommentWrapper } from './PostView.style';

import { changeDateFromISO } from './BlogPage';
import { Wrapper, SideBar, SideBarLeft, SideBarLinks } from '../../../sections/landing/Landing.styles';

import { FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa'

const PostView = () => {
    const [specificPost, setSpecificPost] = useState({});
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(null)
    const [discussionPost, setDiscussionPost] = useState('');

    const { id } = useParams();

    const beatLoaderStyle = {
        margin: '0 auto',
        marginBottom: '60px',
        width: '20%',
        display: 'flex',
        justifyContent: 'center'
    }

    async function postCommentToBlog(discussionPost) {
        const postViewerComment = await fetch(`https://godwin-codes-backend-822451deac42.herokuapp.com/api/update-post/${id}/comment`, {
            method: 'PATCH',
            headers: {
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body: JSON.stringify({ comment: discussionPost })
        });
        const data = await postViewerComment.json();
        console.log(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!discussionPost) alert('Please add a comment');
        setComments([...comments, discussionPost].reverse());
        postCommentToBlog(discussionPost);
        setDiscussionPost('');
    }

    useEffect(() => {
        async function getOneSpecificBlogPost(){
            try{
                setLoading(true)
                const response = await fetch(`https://godwin-codes-backend-822451deac42.herokuapp.com/api/blogs/${id}`);
                const data = await response.json();
                console.log(data)
                setSpecificPost(data);
                setComments(data.comments.reverse());
                setLoading(false);
            } catch(err){
                console.log(err)
            }
        }

        getOneSpecificBlogPost()
    }, [id])

    return(
        <Wrapper>
            {
                loading ? (
                    <div style={ beatLoaderStyle }> 
                        <BeatLoader color="#FFF"/>
                    </div>
                ) : (
                    <PostViewWrapper>
                        <SideBarLeft>
                            <hr />
                            <SideBarLinks className="icons" target="_blank" href="https://www.instagram.com/godwin.codes/"><FaInstagram /></SideBarLinks>
                            <SideBarLinks className="icons"target="_blank" href="https://www.linkedin.com/in/godwin-olletey-4438a4166/"><FaLinkedin /></SideBarLinks>
                            <SideBarLinks target="_blank" href="https://github.com/godwinolle"><FaGithub /></SideBarLinks>
                            <hr />
                        </SideBarLeft>
                        <SideBar>
                            <SideBarLinks href="mailto:godwinolle@gmail.com">godwinolle@gmail.com</SideBarLinks>
                        </SideBar>
                        <h2>{ specificPost.title }</h2>
                        <p className="postInfo"> { changeDateFromISO(specificPost.createdAt) } | { specificPost.minutesToRead } minute read</p>
                        <img src={`${ specificPost.imageUrl }`} alt={`${specificPost.title} is a good read!`} />
                        <ReactMarkdown className="post" children={ specificPost.postContent } />
                        {/* <Markdown remarkPlugins={ [remarkGfm] }>{ specificPost.post }</Markdown> */}
                        <h4>Discussions ({ comments.length })</h4>
                        <form onSubmit={ handleSubmit }>
                            <textarea type="text" placeholder="Add to the discussion" value={ discussionPost } onChange={ (e) => setDiscussionPost(e.target.value) } />
                            <br />
                            <button type="submit">Submit</button>
                        </form>
                        {
                            comments.map((comment, index) => {
                                return(
                                    <CommentWrapper key={ index }>
                                        <img src={CommentsImage} alt="godwin rodrick comments " />
                                        <p>{ comment }</p>
                                    </CommentWrapper>
                                )
                            })
                        }
                    </PostViewWrapper>
                )
            }
        </Wrapper>
    )
}


export default PostView;