import styled from "styled-components";

export const NotFoundWrapper = styled.div`
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFF;
    //margin-top: 100px;

    .four-oh-four{
        font-size: 170px;
        font-weight: 400;
        letter-spacing: 0.03em;
    }
    .four-oh-four-message{
        font-weight: 100;
        margin-bottom: 40px;
        font-weight: 14px;
    }

    button{
        width: 90%;
        padding: 12px;
        color: #FFF;
        font-size: 11px;
        font-weight: 100;
        font-family: 'Montserrat', sans-serif;
        background-color: #2F3749;
        outline: none;
        border-radius: 2px;
        border: 1px solid transparent;
        cursor: pointer;

        :hover{
            border: 1px solid #FFF;
        }
    }
`