import styled from "styled-components";

export const PostViewWrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    color: #FFF;

    h2{
        font-family: 'Titillium Web', sans-serif;
        font-size: 40px;
    }
    .post{
        margin-top: 40px;
        line-height: 2;

        h3{
            font-size: 26px;
            margin-top: 10px;
        }
        li{
            margin-left: 2em;
        }
        p{
            font-weight: 100;
            font-size: 14px;
        }
    }
    p.postInfo{
        font-size: 13px;
    }
    h4{
        margin-top: 50px;
        margin-bottom: 15px;
        font-size: 22px;
    }

    form textarea{
        margin-top: 10px;
        width: 680px;
        padding: 10px 20px 20px 20px;
        background-color: #202633;
        outline: none;
        color: #FFF;
        resize: none;
        border-radius: 10px;
        border: 1px solid rgb(200,200,200,49);
    }

    form button{
        margin-top: 6px;
        width: 100px;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid rgb(200,200,200,49);
        background-color: #202633;
        color: #FFF;
        font-family: 'Courier New', Courier, monospace;
        margin-bottom: 16px;
    }

    img{
        width: 100%;
        margin-top: 20px;
        border-radius: 16px;
    }

    @media screen and (max-width: 768px){
        form textarea{
            width: 90%;
        }

        .post{
            code{
                font-size: 10px;
            }
        }
    }
`

export const CommentWrapper = styled.div`
    display: flex;

    img{
        width: 30px;
        margin-right: 8px;
        border-radius: 16px;
    }

    p{
        font-family: 'Courier New', Courier, monospace;
        font-size: 14px;
        width: 550px;
        margin: 20px 30px 10px 0;
        padding: 20px;
        border: 1px solid #808080;
        border-radius: 5px;
    }
`

export const InputStyle = styled.input`
    margin-left: auto;
    text-align: center;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #FFF;
    font-size: 16px;
    padding: 10px;
    outline: none;
    border-radius: 20px;
    border: 1px solid rgb(200,200,200,49);
    background-color: #202633;

    @media screen and (max-width: 768px){
        margin-left: 0;
        font-size: 10px;
    }
`

export const InputDivStyle = styled.div`
    margin: 5vh auto 10vh auto;
    width: 40%;
`
