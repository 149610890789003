import { NotFoundWrapper } from "./NotFound.styles"; //stylings for error
import { Link } from "react-router-dom";

const NotFound = () => {
    return(
        <NotFoundWrapper>
            <div>
                <h2 className="four-oh-four">404</h2>
                <p className="four-oh-four-message">Woah you went a bit too far!</p>
                <Link to="/"> <button>Go Home</button> </Link>
            </div>
        </NotFoundWrapper>
    )
}

export default NotFound;