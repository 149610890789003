import { FooterWrapper } from "./footer.styles"
import { Wrapper } from "../../../sections/landing/Landing.styles"

const year = new Date().getFullYear()
const Footer= () => {
    return(
        <FooterWrapper>
            <Wrapper>
                <p>Developed by Godwin Rodrick &copy; { year }</p>
            </Wrapper>
        </FooterWrapper>
    )
}

export default Footer