import styled from "styled-components";

export const NavbarWrapper = styled.nav`
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 50px auto;
    font-family: 'Montserrat', sans-serif;
    color: #FFF;

    a{
        color: #FFF;
        text-decoration: none;
    }
    .logo{
        font-family: 'Titillium Web', sans-serif;
        font-size: 18px;
        cursor: pointer;
    }
    .logo span{
        text-shadow: 0 0 1px #FFF, 0 0 1px #FFF, 0 0 1px #FFF, 0 0 1px #FFF;
        color: #202633;
    }
`
export const ListOptions = styled.div`
    width: 40%;

    @media screen and (max-width: 768px){
        display: none;
    }

    ul{
        display: flex;
        justify-content: space-between;
        list-style: none;
        font-family: 'Courier Prime', sans-serif;   
    }

    li{
        cursor: pointer;
    }

    a{
        color: #FFF;
        text-decoration: none;
    }

    .hover-effect:after {    
        background: none repeat scroll 0 0 transparent;
        content: "";
        display: block;
        height: 2px;
        position: relative;
        background: #fff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    .hover-effect:hover:after { 
        width: 100%; 
    }

    li span{
        border: 1px solid #FFF;
        border-radius: 3px;
        padding: 5px 10px;
    }

`

export const MobileListHamburger = styled.div`
    @media screen and (min-width: 768px){
        display: none;
    }
    div{
        margin: 5px auto 0 auto;
        background-color: #FFF;
        height: 3px;
        width: 20px;
        border-radius: 20px;
        cursor: pointer;
    }
    div:nth-child(2){
        width: 30px;
    }


`

export const MobileListOptions = styled.div`
    @media screen and (min-width: 768px){
        display: none;
    }

    font-family: 'Courier Prime', sans-serif;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 70%;
    animation: navAnim 1s ease 0s 1 normal forwards;
    background-color: #FFF;
    z-index: 999;

    .close-menu{
        font-size: 45px;
        position: fixed;
        top: 2;
        right: 0;
    }

    ul{
        list-style: none;
        margin: 5em auto;
    }
    ul li{
        margin-bottom: 60px;
        text-align: center;
        font-size: 20px;
    }

    ul li .mobile-link {
        color: #222222;
        text-decoration: none;
    }
    li span{
        border: 1px solid #000;
        border-radius: 3px;
        padding: 5px 10px;
    }

    .connect-with-me {
        text-align: center;
        font-size: 20px;
    }
    .connect-with-me p{
        margin-bottom: 50px;
    }
    .connect-with-me a{
        color: #222222;
    }
    

    @keyframes navAnim {
        0% {
            opacity: 0;
            transform: translateX(50px);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
`
