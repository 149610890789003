import { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { Wrapper } from '../../../sections/landing/Landing.styles';
import { InputStyle, InputDivStyle } from "./PostView.style";
import { BeatLoader } from "react-spinners";

import BlogsPreview from "../../../components/blogsPreview/blogsPreview";

const beatLoaderStyle = {
    margin: '0 auto',
    marginBottom: '60px',
    width: '20%',
    display: 'flex',
    justifyContent: 'center'
}

const BlogPage = () => {
    const [loading, setLoading] = useState(null);
    const [blogPosts, setBlogPosts] = useState([]);
    const [searchBlog, setSearchBlog] = useState('');

    async function getAllBlogPostsFromAPI(){ //ON PAGE OPEN, THE FIRST THING DONE IS POPULATE THE BLOG POSTS TO SHOW ON THE SCREEN
        try{
            setLoading(true) //loading begins here
            const response = await fetch(`https://godwin-codes-backend-822451deac42.herokuapp.com/api/blogs`);
            const data = await response.json();
            console.log(data)
            setBlogPosts(data.reverse()) //DESCENDING ORDER OF BLOGS BASED ON DATE CREATED, NEWER COMES FIRST
            setLoading(false) //loading ends here
        } catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{ //ON PAGE FIRST LOAD UP, HAVE ALL BLOGS SHOW PRETTY MUCH
        getAllBlogPostsFromAPI()
    }, [])

    return(
        <Wrapper>
            <InputDivStyle>
                <InputStyle type="text" placeholder="Search for an article" value={ searchBlog } onChange={ (e) => setSearchBlog(e.target.value) }/>
            </InputDivStyle>
            {
                loading && (
                    <div style={ beatLoaderStyle }>
                        <BeatLoader color="#FFF"/>
                    </div>
                )
            }
            {
                blogPosts.filter(post => {
                    if(searchBlog === '') return post;
                    else if(post.title.toLowerCase().includes(searchBlog.toLowerCase())) return post;
                    else return '';
                }).map((post, index) => {
                    return (
                        <Link key={ index } style={{ textDecoration: 'none' }}to={`/blog/${ post._id }`}>
                            <BlogsPreview title={ post.title } description={ post.description } date={ changeDateFromISO(post.createdAt) } minutesRead={ post.minutesToRead }/>
                        </Link>
                    )
                })
            }
        </Wrapper>
    )
}

export function changeDateFromISO( date ){ //CHANGING PAGE DATE FROM ISO TO NORMAL DATE VIEW EX. MON DEC 20 2021
    return new Date(date).toDateString();
}


export default BlogPage;
