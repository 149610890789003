import React from 'react';
import { FaTools, FaExternalLinkAlt, FaGithub } from 'react-icons/fa';
import { ProjectWrapper } from './project.styles';

const Project = ({ project, description, image, githubUrl, webPageUrl, tools }) => {
    return(
        <ProjectWrapper>
            <div>
                <p className="featured-project">Featured Project</p>
                <h3>{ project }</h3>
                <div className="description">
                    <span><FaTools /> </span>
                    {
                        tools.map((tool, index) => {
                            return (<span className="project-tools" key={ index }>{ tool }</span>)
                        })
                    }
                    <p>{ description }</p>
                    {
                        webPageUrl ? (
                            <span className="project-github-link"><a href={ webPageUrl } target="_blank" rel="noreferrer"><FaExternalLinkAlt /></a></span>
                        ) : 
                        (
                            <span className="project-github-link"><a href={ githubUrl } target="_blank" rel="noreferrer"><FaGithub /></a></span>
                        )
                    }
                </div>
            </div>
            <div>
                <img src={image} alt="project screenshot" />
            </div>
        </ProjectWrapper>
    )
}

export default Project;