import styled from "styled-components";

export const FooterWrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    color: #FFF;
    font-size: 15px;
    text-align: center;
    margin: 60px;
    font-weight: 100;

    @media screen and (max-width: 768px){
        font-size: 10px;
    }
`