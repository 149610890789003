import styled from "styled-components"

export const BlogPreviewWrapper = styled.div`
    font-family: 'Titillium Web', sans-serif;
    color: #FFF;

    h3{
        font-size: 30px;
        margin-bottom: 10px;
    }
    p{
        font-family: 'Montserrat', sans-serif;
    }
    p.trimmedArticle{
        margin-bottom: 10px;
    }
    p.blog-info{
        font-size: 12px;
    }
    hr{
        margin: 40px 0;
    }
`