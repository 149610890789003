import React from 'react';
import { SectionHeading } from '../about/About.styles';
import { OtherProjectsSection } from './Projects.styles';

import Project from '../../components/project/project';
import OtherProjects from '../../components/otherProjects/OtherProjects';


//importing images
import project1 from '../../assets/project1.svg';
import project2 from '../../assets/project2.svg';
import project3 from '../../assets/project3.svg';
import project4 from '../../assets/jsonConverter.svg';

//descriptions of projects
let project1Description = 'A portfolio website for a photographer, where users are able to view a slide show of photographers work, get in contact, as well as view prices.';
let project2Description = 'A virtual bookclub where users are able to keep track of books they are reading, planning to read, as well as wish to read. Utilizes Google Books API.';
let project3Description = 'A kanban style board that utilizes the agile project management tool designed to help visualize work, limit work-in-progress, and maximize efficiency (or flow).';
let project4Description = 'A dynamic JSON converter application that transforms any data into a specified JSON format via a very simple user friendly web interface.';
let otherProject1Description = 'A command line interface that allows users to generate either a random quote, or random episode to watch as well as other features from the popular show The Office.'
let otherProject2Description = 'A command line interface that acts as the first phase of a compiler (Lexical Analyzer) by breaking each line down one by one and performing validation checks.'
let otherProject3Description = 'A portfolio website for myself, created in early 2021 with a few updates made throughout the year. Also allows for users to toggle between light and dark mode.'


//github and other links to projects
let project1Url = 'https://marcyphotostudio.com/';
let project2GithubUrl = 'https://github.com/godwinolle/bookshelf';
let project3GithubUrl = 'https://github.com/godwinolle/kanban-board';
let project4Url = 'https://json-converter-jade.vercel.app/'
let otherProject1GithubUrl = 'https://github.com/godwinolle/random-Office';
let otherProject2GithubUrl = 'https://github.com/godwinolle/SyntaxAnalyzer';
let otherProject3WebPageUrl = 'https://godwin-portfolio.web.app/';

//tools used for each project
let project1Tools = ['REACT', 'FIREBASE'];
let project2Tools = ['REACT', 'FIREBASE'];
let project3Tools = ['REACT'];
let project4Tools = ['NEXTJS', 'VERCEL', 'OPENAI']
let otherProject1Tools = ['PYTHON'];
let otherProject2Tools = ['JAVA'];
let otherProject3Tools = ['REACT', 'FIREBASE'];

const Projects = () => {
    return(
        <div data-aos="fade-up" id="projects">
            <SectionHeading>
                .projects()
            </SectionHeading>
            <Project project="JSON Converter" image={ project4 } description={ project4Description } webPageUrl={ project4Url } tools={ project4Tools }/>
            <Project project="Marcy's Photo Studio" image={ project1 } description={ project1Description } webPageUrl={ project1Url } tools={ project1Tools }/>
            <Project project="BookShelf.io" image={ project2 } description={ project2Description } githubUrl={ project2GithubUrl } tools={ project2Tools }/>
            <Project project="Kanban Board" image={ project3 } description={ project3Description } githubUrl={ project3GithubUrl } tools={ project3Tools }/>

            <OtherProjectsSection>
                <OtherProjects project="The Office CLI" description={ otherProject1Description } githubUrl={ otherProject1GithubUrl } tools={ otherProject1Tools }/>
                <OtherProjects project="Lexical Analyzer" description={ otherProject2Description } githubUrl={ otherProject2GithubUrl } tools={ otherProject2Tools }/>
                <OtherProjects project="Portfolio V2" description={ otherProject3Description } webPageUrl={ otherProject3WebPageUrl } tools={ otherProject3Tools }/>
            </OtherProjectsSection>
        </div>
    )
}

export default Projects;