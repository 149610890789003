import { FaTools, FaExternalLinkAlt, FaGithub } from 'react-icons/fa';

import { OtherProjectsWrapper } from '../project/project.styles';

const OtherProjects = ({ project, description, webPageUrl, githubUrl, tools}) => {
    return(
        <OtherProjectsWrapper>
            <h3>{ project }</h3>
            <div className='description'>
                <span><FaTools /></span>
                {
                    tools.map((tool, index) => {
                        return(
                            <span key={ index } className='project-tools'>{ tool }</span>
                        )
                    })
                }
                <p>{ description }</p>
                {
                    webPageUrl ? (
                        <span className='project-github-link'><a href={ webPageUrl } target="_blank" rel="noreferrer"><FaExternalLinkAlt /></a></span>
                    ) : (
                        <span className='project-github-link'><a href={ githubUrl } target="_blank" rel="noreferrer"><FaGithub /></a></span>
                    )
                }
            </div>
        </OtherProjectsWrapper>
    )
}

export default OtherProjects;