import styled from "styled-components";

export const ProjectWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    color: #FFF;
    margin-bottom: 80px;

    .featured-project{
        font-family: 'Courier Prime', monospace;
        font-size: 18px;
        margin-bottom: 20px;
    }

    h3{
        font-family: 'Titillium Web', 'sans-serif';
        margin-bottom: 25px;
        font-size: 25px;
    }

    .description{
        font-family: 'Montserrat', 'sans-serif';
        background-color: #2F3749;
        border-radius: 5px;
        padding: 30px;
        width: 80%;
        font-size: 13px;
    }
    .description p{
        margin: 20px 0;
        font-weight: 100;
        color: #F6F6F6;
    }
    .description span{
        font-weight: 400;
    }
    .description .project-tools{
        background-color: #434B5B;
        border-radius: 1px;
        padding: 3px;
        margin-right: 8px;
        font-size: 13px;
        letter-spacing: 0.1em;
    }
    .description .project-github-link a{ 
        font-size: 16px;
        color: #FFF;
    }   

    @media screen and (max-width: 768px){
        flex-direction: column-reverse;

        h3{
            font-size: 23px;
        }

        .description{
            width: 100%;
            font-size: 13px;
        }

        div:nth-child(2) img{
            width: 100%;
        }
    }
`

export const OtherProjectsWrapper = styled.div`
    color: #FFF;
    background-color: #2F3749;
    border-radius: 5px;
    padding: 30px;
    width: 32%;

    h3{
        font-family: 'Titillium Web', 'sans-serif';
        margin-bottom: 15px;
        font-size: 22px;
    }

    .description{
        font-family: 'Montserrat', 'sans-serif';
        font-size: 13px;
    }

    .description .project-tools{
        background-color: #434B5B;
        border-radius: 1px;
        padding: 3px;
        margin: 0 8px 0 3px;
        letter-spacing: 0.1em;
    }

    .description p{
        margin: 15px 0 10px 0;
        font-weight: 100;  
    }

    .description .project-github-link a{
        font-size: 16px;
        color: #FFF;
    }   

    @media screen and (max-width: 768px){
        width: 100%;
        margin-bottom: 15px;
    }
`