import React from 'react'
import { SectionHeading, AboutMeP, AboutMeTechnologies, AboutWrapper, UList, List } from './About.styles';

const intro = `Hello again, I'm Godwin, and I find joy in bringing my ideas to life through code whether it's a personal endeavor or a challenging project. My journey into the world of programming began during high school with an Introduction to Computer Science course, where I delved into coding using Hour of Code’s platform.`

const languages = [
    'Elm',
    'Elixir',
    'Java',
    'JavaScript', 
    'TypeScript',
    'Python', 
    'C++', 
    'HTML', 
    'CSS'
]

const frameworks = [
    'ReactJS', 
    'NodeJS',
    'Flask', 
    'JUnit'
]

const services = [ 
    'AWS Lambda', 
    'Firebase', 
    'SupaBase', 
    'Heroku', 
    'AWS S3'
]

const databases = [
    'PostgreSQL',
    'MongoDB',
    'AWS DynamoDB'

]

const About = () => {
    return(
        <AboutWrapper id="about-godwin" data-aos="fade-up">
            <SectionHeading>
                .about("godwin");
            </SectionHeading>
            <AboutMeP>
                { intro }
            </AboutMeP>
            <br />
            <AboutMeP>
                I proudly graduated from Towson University with a degree in Computer Science, and throughout my four years at Towson, I was fortunate enough to work for a huge corporation as an <b>Applications 
                Developer Co-Op</b>, as a <b>Frontend Developer</b> at a startup, as well as a <b>Cybersecurity Analyst Intern</b> at 
                an investment management firm.
            </AboutMeP>
            <br />
            <AboutMeP>
                Currently, I'm engaged in an exciting role as a <strong>Software Engineer</strong> with a Cyber Insurance startup. 
                Each opportunity has shaped me into a more refined and capable professional, and I'm committed to continuous growth and 
                learning. There's no end in sight to my journey of self-improvement.
            </AboutMeP>
            <br />
            <AboutMeTechnologies>
            <p>Here are a few technologies I’ve had the privilege of working with:</p>
            <List>
                <div>
                    <p>LANGUAGES</p>
                    <UList>
                        {
                            languages.map((language, i) => (
                                <li key={ i }>{ language }</li>
                            )
                        )}
                    </UList>
                </div>
                <div>
                    <p>FRAMEWORKS</p>
                    <UList>
                        {
                            frameworks.map((framework, i) => (
                                <li key={ i }>{ framework }</li>
                            )
                        )}
                    </UList>
                </div>
                <div>
                    <p>TOOLS</p>
                    <UList> 
                        {
                            services.map((service, i) => (
                                <li key={ i }>{ service }</li>
                            )
                        )}
                    </UList>
                </div>
                <div>
                    <p>DATABASES</p>
                    <UList> 
                        {
                            databases.map((database, i) => (
                                <li key={ i }>{ database }</li>
                            )
                        )}
                    </UList>
                </div>
            </List>
            </AboutMeTechnologies>
        </AboutWrapper>
    )
}

export default About;