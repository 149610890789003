import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { LandingWrapper, Heading, DescriptionWrapper, Description, HelloWrapper, Tagline, ImageWrapper } from './Landing.styles';

import me from '../../assets/me.svg'

const descriptions = ['Software Engineer.', ' Cybersecurity Analyst. ', 'Lifelong Learner.', 'Clean Coder Since 1999.']

const Landing = () => {
    return(
        <LandingWrapper>
            <DescriptionWrapper>
                <HelloWrapper>Hi there 👋  my name is </HelloWrapper>
                <Heading>
                    Godwin Rodrick.
                </Heading>
                <Description>
                    <span style={{ color: '#565E6E' }}>&lt;</span>
                    <ReactTypingEffect 
                        text={descriptions} 
                        speed={100}
                        eraseSpeed={50}
                    />
                    <span style={{ color: '#565E6E' }}>/&gt;</span>
                </Description>
                <br />
                <Tagline>
                    I’m a software engineer with a passion for solving problems through writing code. 
                    Ambitious and eager to learn.
                </Tagline>
            </DescriptionWrapper>
            <ImageWrapper>
                <img src={ me } alt="weclome to godwin's website" />
            </ImageWrapper>
        </LandingWrapper>
    )
} 

export default Landing;