import { useEffect } from 'react';
import Aos from 'aos'; //animate on scroll importing
import 'aos/dist/aos.css';
import { Wrapper, SideBar, SideBarLeft, SideBarLinks } from '../../sections/landing/Landing.styles';

import { FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa'

//importing sections
import Landing from '../../sections/landing/Landing';
import About from '../../sections/about/About';

import Projects from '../../sections/projects/Projects';

function MainPage() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  })

  return (
    <div>
      <SideBarLeft>
        <hr />
        <SideBarLinks className="icons" target="_blank" href="https://www.instagram.com/godwin.codes/"><FaInstagram /></SideBarLinks>
        <SideBarLinks className="icons" target="_blank" href="https://www.linkedin.com/in/godwin-olletey-4438a4166/"><FaLinkedin /></SideBarLinks>
        <SideBarLinks target="_blank" href="https://github.com/godwinolle"><FaGithub /></SideBarLinks>
        <hr />
      </SideBarLeft>
      <SideBar>
        <SideBarLinks href="mailto:godwinolle@gmail.com">godwinolle@gmail.com</SideBarLinks>
      </SideBar>
      <Wrapper>
        <Landing />
        <About />
        <Projects />
      </Wrapper>
    </div>
  );
}

export default MainPage;